.usersuggestion {


  @include add_fluid_space('xl','margin', 'bottom' );

  .submit_button {
    @include add_fluid_space('s', 'margin', 'top');
    float: right;
  }

  .formHint {
    display: none;
  }

  .error {
    color: color('orange');
  }

  form {
    margin: 0px auto;
    max-width: $max-content-width;

    .errorField {
      margin-left: 8px;
      margin-bottom: 5px;
    }
  }
}

.usersuggestion__intro {

  margin: 0px auto;
  max-width: $max-content-width;

  @include breakpoint('vp-main') {
    @include add_fluid_space('l', 'padding', 'top');
  }

  @include add_fluid_space('s', 'padding', 'bottom');
}


.usersuggestion__headline {

  @include add_fluid_space('s', 'margin', 'bottom');
}

.usersuggestion__text {

}
