.login-modal {

  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}


.login-modal__content {

  background-color: color('gray');
  border-radius: 6px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);

  //margin: 20% auto; /* 15% from the top and centered */

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: 90%; /* Could be more or less, depending on screen size */
  //height: 90%; /* Could be more or less, depending on screen size */
  max-width: 750px;
  position: relative;
}

.login-modal__inner_content {
  //padding: 0 20% 0 20%;

}

.login-model__logo {

    flex: 1;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    height: 200px;
    height: 74px;
    width: 100%;
    @include add_space('m','margin','top');
    background-image: url('../assets/img/media-innovators-logo.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.login-modal__close {
  color: color('green');
  cursor:pointer;
  position: absolute;
  right: 5px;
  top: 5px;

  :hover,
  :focus {
    color: color('blue');
    text-decoration: none;
    cursor: pointer;
  }
}

.login-modal__close__icon {
  &:before {
    font-size: 36px;
    margin: 0px;
  }
}

.login-modal_headline {
  text-align: center;
  @include add_space('m','margin','top');
  @include breakpoint('vp-480') {
    @include add_space('l','margin','top');
  }

  @include add_space('s','margin','bottom');
  font-size: 30px;
  font-font-size-adjust: $font-family-headline;
}

.login-modal__password-reset {
  margin: 0px auto;
  text-align: center;
  @include add_space('m','margin','bottom');

  a {
    color: color('text');
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: color('text-dark');
    }
  }
}


.login-modal__login-form {

  input {
    border: 0px;
    padding: 10px;
    width: 100%;
    min-width: 200px;
    max-width: 440px;
  }

  input, button {
    @include add_space('s','margin','bottom');
  }

  form {
    text-align: center;
  }
}

.login-modal__login_input_container {

}

.login-modal__login_button {
  color: color('white');
  background-color: color('pink');
  border: 0px;
  font-family: $font-family-headline;
  font-size: 20px;
  height: 36px;
  cursor: pointer;
  width: 200px;
  text-transform: uppercase;

  &:hover {
    border: 2px solid color('pink');
    background-color: color('white');
    color: color('pink');
  }
}

.login-modal__error {

  max-width: 440px;
  margin: 0px auto;
  color: color('pink');
  @include add_space('s','margin','bottom');
}
