.profile_image {
  background-color: color('green');
  display: flex;
  clip-path: polygon(50% 0, 0 24%, 0 76%, 50% 100%, 100% 76%, 100% 24%);
  position: relative;
}

.profile_image__link {

  &:hover {

    .profile_image {
      background-color: color('green', 0.8);
    }
  }


}

.profile_image__normal {
  @include fluid-property('height', $mobile-min-width, $main-stage-width, 160px, 332px);
  @include fluid-property('width', $mobile-min-width, $main-stage-width, 140px, 290px);
}

.profile_image__thumb {
  width: 87px;
  height: 100px;
}

.profile_image_container {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  top:1px;
  left: 1px;
  position: absolute;
  clip-path: polygon(50% 0, 0 24%, 0 76%, 50% 100%, 100% 76%, 100% 24%);
}

.profile_image__image {

  height: 100%;
  width: auto;
  top:1px;
  left:50%;
  transform: translateX(-50%);
  position: absolute;

  &:after {

    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #aaa;
  }

}
