
.module__page_header_main {
  background-color: color('blue-light');
  @include add_fluid_space('s','padding', 'bottom'  );
  @include add_fluid_space('s','padding', 'top' );


  @include breakpoint('vp-main') {
    width: 85%;
    @include add_fluid_space('m','padding', 'top' );
  }
}

.module__page_header_main__headline {
  @include add_fluid_space('s','padding', 'left', 'right');

}

.module__page_header_main__text  {
  @include add_fluid_space('s','padding', 'top' );

  @include fluid-type($relative-min-width, $main-stage-width, 14px, 22px);
  @include fluid-property('line-height', $relative-min-width, $main-stage-width, 18px, 30px);
  position: relative;

  @include breakpointMax('vp-main') {
    @include add_fluid_space('s','padding', 'left', 'right');
  }

  @include breakpoint('vp-main') {
    width: 54%;
    left: 31.01%;
  }


}
