.module__image_text_teaser_box {



  @include add_fluid_space('m','margin', 'top', 'bottom' );
  @include add_fluid_space('s','padding', 'left', 'right');

  &:first-child {
    @include add_fluid_space('reset','margin', 'top' );
  }

  display: flex;
  flex-direction: column;
  background-color: color('dark-green');
}


.module__image_text_teaser_box__content {
  display: flex;
  flex-direction: column;

  align-items: center;
  @media (min-width : 1100px) {
    flex-direction: row;
    align-items: unset;
  }

}

.module__image_text_teaser_box__text {
  flex: 1;

  color: color('white');

  a {
    color: color('orange');
  }

  h2 {
    color: color('orange');
    @include font-copy();
    font-weight: normal;

    &:not(:first-child) {
      @include add_fluid_space('m','padding', 'top');
    }


    @include add_fluid_space('xxs','padding', 'bottom');
  }

  h1 {
    font-family: $font-family;
    font-weight: normal;
    @include fluid-type($mobile-min-width, $main-stage-width, 24px, 34px);
    @include fluid-property('line-height', $mobile-min-width, $main-stage-width, 25px, 35px);
    display: flex;
    height: 100%;
    align-items: center;
    color: color('orange');

    @include add_fluid_space('s','padding', 'left','right');
    text-align: center;

    @media (min-width : 1100px) {
      text-align: left;
      @include add_fluid_space('m','padding', 'left','right');
      @include fluid-type($mobile-min-width, $main-stage-width, 34px, 38px);
      @include fluid-property('line-height', $mobile-min-width, $main-stage-width, 35px, 45px);
    }


  }


  @include add_fluid_space('s','margin', 'top', 'bottom' );
  @media (min-width : 1100px) {
    @include add_fluid_space('l','margin', 'top', 'bottom' );
  }

}

.module__image_text_teaser_box__image_container {

  @include add_fluid_space('l','padding', 'left', 'right');
  @include add_fluid_space('l','padding', 'top', 'bottom');

  display: flex;
  flex: 1;
  max-width: 50%;

  @media (max-width : 1100px) {
    @include add_fluid_space('s','padding', 'left', 'right');
    @include add_fluid_space('s','padding', 'top', 'bottom');
  }

}

.module__image_text_teaser_box__image {
  height: auto;
  max-width: 100%;
  @media (width : 1100px) {
    max-width: 80%;
  }
}
