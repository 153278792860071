.module__news_box {
  @include add_fluid_space('m','margin', 'top', 'bottom' );
  @include add_fluid_space('s','padding', 'left', 'right');
  display: flex;
  flex-direction: column;
}

.module__news_box__headline {
  font-family: $font-family-headline-serif;
  font-weight: normal;
  text-align: center;
  @include add_fluid_space('s', 'margin', 'top', 'bottom');;
  color: color('green');
    @include fluid-type($relative-min-width, $mobile-min-width, 18px, 34px);
}

.module__news_box__content {
  display: flex;
  flex-direction: column;
  background-color: color('off-white');

  @include breakpoint('vp-main') {
    flex-direction: row;
  }
}

.module__news_box__text {
  flex: 1;
  @include add_fluid_space('s', 'margin', 'top');;
  @include add_fluid_space('xs', 'padding', 'left', 'right');;
  @include breakpoint('vp-main') {
    @include add_fluid_space('reset', 'padding', 'left');;
    // because of image position (vertical alignment
    // margin-top: 3px;
  }
}

.module__news_box__image_container {
  //@include add_fluid_space('xxs','margin', 'top', 'bottom' );
  @include breakpoint('vp-main') {
    @include add_fluid_space('sm','margin', 'right');

  }
  //width: 675px;
  display: flex;
  align-items: center;
  flex: 1;

}

.module__news_box__image {
  width: 100%;
  height: auto;
  max-width: 675px;
}
