@font-face {
  font-family: 'BlenderProMedium';
  font-weight: 500;
  src: url("../assets/fonts/BlenderPro-MediumWeb.eot"), url("../assets/fonts/BlenderPro-MediumWeb.woff") format("woff");
}

@font-face {
  font-family: 'BlenderProBold';
  src: url("../assets/fonts/BlenderPro-Bold.eot");
  src: url("../assets/fonts/BlenderPro-Bold.woff2") format("woff2"), url("../assets/fonts/BlenderPro-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: 'BlenderProBook';
  src: url("../assets/fonts/BlenderPro-Book.eot");
  src: url("../assets/fonts/BlenderPro-Book.woff2") format("woff2"), url("../assets/fonts/BlenderPro-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal
}


@font-face {
  font-family: 'MediaLab_Icon';
  src: url("../assets/fonts/MediaLab_Icon.eot?f3qmx2");
  src: url("../assets/fonts/MediaLab_Icon.eot?f3qmx2#iefix") format("embedded-opentype"), url("../assets/fonts/MediaLab_Icon.ttf?f3qmx2") format("truetype"), url("../assets/fonts/MediaLab_Icon.woff?f3qmx2") format("woff");
  font-weight: normal;
  font-style: normal
}


/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * flood-std:
 *   - http://typekit.com/eulas/000000000000000000012fc3
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2019-02-18 16:53:07 UTC"}*/
@import url("https://p.typekit.net/p.css?s=1&k=aos7ysi&ht=tk&f=18085&a=549867&app=typekit&e=css"); @font-face {
  font-family: "flood-std";
  src: url("https://use.typekit.net/af/6da923/000000000000000000012fc3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/6da923/000000000000000000012fc3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/6da923/000000000000000000012fc3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}


@font-face {
  font-family: 'idb_icons';
  src: url('../assets/fonts/icons/idb_icons.eot?52403135');
  src: url('../assets/fonts/icons/idb_icons.eot?52403135#iefix') format('embedded-opentype'),
  url('../assets/fonts/icons/idb_icons.woff2?52403135') format('woff2'),
  url('../assets/fonts/icons/idb_icons.woff?52403135') format('woff'),
  url('../assets/fonts/icons/idb_icons.ttf?52403135') format('truetype'),
  url('../assets/fonts/icons/idb_icons.svg?52403135#idb_icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
