.button_light {

  @include fluid-property('height', $mobile-min-width, $main-stage-width, 35px, 45px);
  border: 1px solid color('orange');
  color: color('orange');

  display: inline-flex;
  align-items: center;
  flex-direction: row;
  padding: 0px 20px 0px 20px;
  text-decoration: none;

  @include font-button();

  &:hover {
    background-color: color('orange');
    color: color('white');
  }


}
