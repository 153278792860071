.profile_edit_meta {

  @include add_fluid_space('s', 'margin', 'top');

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;


  max-width: calc(#{$max-content-width} + 20px);
  margin: 0px auto;
  justify-content: space-between;
  padding: 10px;

  @include breakpointMax('vp-480') {

  }

  &.is_superuser {
    border: 1px solid coral;
    &:before {
      content: "DEBUG";
      color: coral;
    }
  }

}

.profile_edit_meta__text_container {
  flex-basis: 100%;
  text-align: center;
}

.profile_edit_meta__profile_button {
  margin-bottom: 15px;
}

.profile_edit_meta__text {


}
