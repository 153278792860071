/*------------------------------------*\
    $MIXINS
\*------------------------------------*/
/*
import font related mixins
 */

@import "../variables";


/*

 */

$breakpoints: () !default;
@mixin breakpoint($breakpoint) {
  @if (map-has-key($breakpoints, $breakpoint)) {
    @if (map-has-key(map-get($breakpoints, $breakpoint), min-height)) {
      @media (min-height: map-get(map-get($breakpoints, $breakpoint), min-height)) {
        @media (min-width: map-get(map-get($breakpoints, $breakpoint), width)) {
          @content;
        }
      }
    } @else {
      @media (min-width: map-get(map-get($breakpoints, $breakpoint), width)) {
        @content;
      }
    }
  } @else {
    @warn "Breakpoint: " + $breakpoint + " is not defined";
  }
}


@mixin breakpointMax($breakpoint) {
  @if (map-has-key($breakpoints, $breakpoint)) {
    @if (map-has-key(map-get($breakpoints, $breakpoint), min-height)) {
      @media (max-height: map-get(map-get($breakpoints, $breakpoint), min-height) - 1px) {
        @media (max-width: map-get(map-get($breakpoints, $breakpoint), width) - 1px) {
          @content;
        }
      }
    } @else {
      @media (max-width: map-get(map-get($breakpoints, $breakpoint), width) - 1px) {
        @content;
      }
    }
  } @else {
    @warn "Breakpoint: " + $breakpoint + " is not defined";
  }
}


/* CSS Transition
	Usage: @include transition(width,0.3s,ease-out);
 */
@mixin transition($transition-property, $transition-time, $method: linear) {
  -webkit-transition: $transition-property $transition-time $method;
  -moz-transition: $transition-property $transition-time $method;
  -ms-transition: $transition-property $transition-time $method;
  -o-transition: $transition-property $transition-time $method;
  transition: $transition-property $transition-time $method;
}


@mixin box-shadow($offsetX, $offsetY, $radius, $spread, $color: color('black')) {
  box-shadow: $offsetX $offsetY $radius $spread $color;
  -webkit-box-shadow: $offsetX $offsetY $radius $spread $color;
  -moz-box-shadow: $offsetX $offsetY $radius $spread $color;
}


@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}


@mixin border-radii($topleft, $topright, $bottomright, $bottomleft) {
  @if $topleft != null and $topleft >= 0 {
    border-top-left-radius: $topleft;;
    -webkit-border-top-left-radius: $topleft;
    -moz-border-radius-topleft: $topleft;
  }

  @if $topright != null and $topright >= 0 {
    border-top-right-radius: $topright;
    -webkit-border-top-right-radius: $topright;
    -moz-border-radius-topright: $topright;
  }

  @if $bottomleft != null and $bottomleft >= 0 {
    border-bottom-left-radius: $bottomleft;
    -webkit-border-bottom-left-radius: $bottomleft;
    -moz-border-radius-bottomleft: $bottomleft;
  }

  @if $bottomright != null and $bottomright >= 0 {
    border-bottom-right-radius: $bottomright;
    -webkit-border-bottom-right-radius: $bottomright;
    -moz-border-radius-bottomright: $bottomright;
  }
}


@mixin gradient($color1, $color2) {
  background-color: $color1;
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$color1}, endColorstr=#{$color2});
  background-image: -moz-linear-gradient(center top, $color1, $color2);
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($color1), to($color2));
}


@mixin fluid-vertical-spacer($spacer-size: 'm', $direction: 'bottom', $space-type:'padding') {


  $space: map-get($v-space-map-mobile, $spacer-size);

  $min-width: $relative-min-width;
  $max-width: $mobile-min-width;
  $factor: 0.5;
  @include fluid-property('#{$space-type}-#{$direction}', $min-width, $max-width, $space*$factor , $space );

    @include breakpoint('vp-375') {

      $min-width: $mobile-min-width;
      $max-width: $mobile-max-width;
      $factor: 1;
      @include fluid-property('#{$space-type}-#{$direction}', $min-width, $max-width, $space*$factor , $space );
    }

  @include breakpoint('vp-main') {
    $space: map-get($v-space-map, $spacer-size);
    $min-width: $main-stage-min-width;
    $max-width: $main-stage-width;
    $factor: 0.5;
    @include fluid-property('#{$space-type}-#{$direction}', $min-width, $max-width, $space*$factor , $space );

  }


}

@mixin fluid-spacer($spacer-size-px, $direction: 'bottom', $space-type:'padding') {

  $space: $spacer-size-px;
  @include fluid-property('#{$space-type}-#{$direction}', $mobile-min-width, $main-stage-width, $space*0.1 , $space );
}



@mixin add_fluid_space($size, $space_type:'padding', $directions...) {

  $props: ();

  $horizontal: false;
  $vertical: false;

  @for $i from 0 to length($directions) {

    $dir: nth($directions, $i + 1);
    @if $dir == 'top' or $dir == 'bottom'{
      $vertical:true;
      $props: append($props, "#{$space_type}-#{$dir}");
    }
    @if $dir == 'left' or $dir == 'right' {
      $horizontal:true;
      $props: append($props, "#{$space_type}-#{$dir}");
    }
  }

  @if($horizontal and $vertical) {
    @error 'add_fluid_space ERROR: only horizontal or vertical values allowed.';
  }
  $min_size:0;
  $max_size:0;

  @if ($horizontal) {
    $min_size: map_get($h-space-map-mobile, $size);
    $max_size: map_get($h-space-map, $size);
  }
  @else if($vertical) {
    $min_size: map_get($v-space-map-mobile, $size);
    $max_size: map_get($v-space-map, $size);
  }
  @else {
    @error "No direction set!";
  }

  $min-width: $relative-min-width;
  $max-width: $main-stage-width;

  @include fluid-properties($props, $min-width, $max-width, $min_size, $max_size);


}

@mixin fluid-horizontal-spacer($spacer-size: 'm', $direction: 'left', $space-type:'padding') {

  $space: map-get($h-space-map-mobile, $spacer-size);
  $space_max: map-get($h-space-map, $spacer-size);
  $min-width: $relative-min-width;
  $max-width: $main-stage-min-width;
  $factor: 1;
  @include fluid-property('#{$space-type}-#{$direction}', $min-width, $max-width, $space*$factor, $space_max);
  /*
    @include breakpoint('vp-375') {
      $space: map-get($h-space-map, $spacer-size);
      $min-width: $mobile-min-width;
      $max-width: $main-stage-width;
      $factor: 1;
      @include fluid-property('#{$space-type}-#{$direction}', $min-width, $max-width, $space*$factor, $space);
    }

    @include breakpoint('vp-main') {
      $space: map-get($h-space-map, $spacer-size);
      $min-width: $main-stage-min-width;
      $max-width: $main-stage-width;
      $factor: 0.5;
      @include fluid-property('#{$space-type}-#{$direction}', $min-width, $max-width, $space*$factor, $space);

    }
  */


}

@mixin fluid-padding($min-vw, $max-vw, $min-padding, $max-padding, $side:'both', $type: 'padding') {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-padding);
  $u4: unit($max-padding);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      @if $side == 'both' {
        #{$type}-left: $min-padding;
        #{$type}-right: $min-padding;
      } @else if ($side == 'left') {
        #{$type}-left: $min-padding;
      } @else {
        #{$type}-right: $min-padding;
      }

      @media screen and (min-width: $min-vw) {
        @if $side == 'both' {
          #{$type}-left: calc(#{$min-padding} + #{strip-unit($max-padding - $min-padding)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
          #{$type}-right: calc(#{$min-padding} + #{strip-unit($max-padding - $min-padding)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
        } @else if ($side == 'left') {
          #{$type}-left: calc(#{$min-padding} + #{strip-unit($max-padding - $min-padding)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
        } @else {
          #{$type}-right: calc(#{$min-padding} + #{strip-unit($max-padding - $min-padding)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
        }
      }
      @media screen and (min-width: $max-vw) {
        @if $side == 'both' {
          #{$type}-left: $max-padding;
          #{$type}-right: $max-padding;
        } @else if ($side == 'left') {
          #{$type}-left: $max-padding;
        } @else {
          #{$type}-right: $max-padding;
        }
      }
    }
  }
}

@mixin fluid-property($property, $min-vw, $max-vw, $min-value, $max-value) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-value);
  $u4: unit($max-value);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      #{$property}: $min-value;

      @media screen and (min-width: $min-vw) and (max-width: $max-vw){
        #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        #{$property}: $max-value;
      }
    }
  }
}



@mixin fluid-properties($properties, $min-vw, $max-vw, $min-value, $max-value) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-value);
  $u4: unit($max-value);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {

    & {
      @each $prop in $properties {
        #{$prop}: $min-value;
      }

      @media screen and (min-width: $min-vw) {

        @for $i from 0 to length($properties) {

          $prop: nth($properties, $i + 1);


          #{$prop}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
        }


      }
      @media screen and (min-width: $max-vw) {
        @each $prop in $properties {
          #{$prop}: $max-value;
        }
      }
    }
  }
}




@mixin rgba-background($hexcolor, $opacity) {
  background-color: $hexcolor;
  background-color: rgba($hexcolor, $opacity);
}


@mixin centerAbsolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


@mixin centerHorizontal {
  position: absolute;
  left: 50%;
  transform: translate(-50.05%, 0); // .05 for FF
}


@mixin centerVertical {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
