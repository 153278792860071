.profile {

  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin: 0px auto;
}



.profile__inner {
  max-width: $max-content-width;
  width: 100%;
  position: relative;

  border: 2px solid color('blue');
  @include breakpoint('vp-480') {
    border: 3px solid color('blue');
  }

  background-color: color('white');
  margin: 10px;
  @include add_fluid_space('s', 'padding', 'left', 'right');
}

.profile__profile-image {
  margin: 0px auto;
  @include add_fluid_space('s', 'margin', 'top', 'bottom');
}



.profile__username {
  @include font-headline-2();
  text-align: center;
  @include add_fluid_space('s', 'margin', 'top', 'bottom');
}



.profile__subhead {
  @include add_fluid_space('xxs', 'padding', 'top', 'bottom');
}

.profile__data__list {

  list-style: none;

  li {
    @include add_fluid_space('xxs', 'margin', 'top');
    border-bottom: 1px solid color('blue');
    @include breakpoint('vp-480') {
      border-bottom: 2px solid color('blue');
    }

  }
}

.profile__abilities {
  @include add_fluid_space('s', 'margin', 'top', 'bottom');


  > div {
    margin-top: 15px;
  }
}

.profile__self_presentation  {
  background-color: color('blue-light');
  border-bottom: 1px solid color('blue');
  @include add_fluid_space('xxs', 'padding', 'top', 'bottom');
  @include add_fluid_space('xxs', 'padding', 'left', 'right');
  @include add_fluid_space('s', 'margin', 'bottom');
}



.profile__contact-rules__list {

  list-style: none;
  display: flex;
  flex-direction: column;

  li {
    @include font-medium-14-18();

      &:before {
        content: "\2022";
        font-size: 16px;
        color: color('blue-bright');
        margin: 0px 15px 0px 5px;
      }
    margin-top: 5px;
    }

}


.profile__social-contacts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  @include add_fluid_space('m', 'margin', 'bottom', 'top');
}

.profile__social-link {

  margin-top: 10px;
  @include fluid-type($mobile-min-width, $main-stage-width, 20px, 40px);
  @include fluid-property('margin-left', $mobile-min-width, $main-stage-width, 5px, 10px);
  @include fluid-property('margin-right', $mobile-min-width, $main-stage-width, 5px, 10px);
}
