.typo_subhead {
  @include font-subhead();
}

.typo_head_2 {
  @include font-headline-2();
}

.typo_head_3 {
  @include font-headline-3();
}

.typo_head_serif {
  @include font-headline-serif();
  color: color('green');  // color('orange');
}

.typo_copy {
  @include font-copy();
}

.typo_copy_small {
  @include font-copy-small();
}

.typo_copy_big {
  @include font-copy-big();
}
