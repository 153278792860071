.home__article_list {
  margin: 0px;
  padding: 0px;

  li {
    list-style: none;
    font-size: 20px;
    padding: 10px 0 0 0;

  }
}

