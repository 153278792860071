.module__page_header_polygon {
  position: relative;
  top: 0px;
  right: 0px;

  z-index: -1;
  display: none;
  //height: 400px;
  background-color: red;
  display: block;

/*
  @include breakpoint('vp-main') {
    display: block;
    top: -180px;
  }

  @include breakpoint('vp-switch') {
    top: -275px;
  }*/

}


.module__page_header_polygon_main {
  position: absolute;
  top: 0px;
  right: 0px;

  height:60px;
  width:150px;

  margin-top: -10px;
  display: none;

  @include breakpoint('vp-main') {
    display: block;
    @include fluid-property('width', $main-stage-min-width, $main-stage-width, 236px, 472px);
    @include fluid-property('height', $main-stage-min-width, $main-stage-width, 88.5px, 177px)
  }




  &:after {
    //position: absolute;
    display: block;
    right: 0px;
    top: 0px;
    //background-color: red;

    height:100%;
    width:100%;
    /*
        height:285px;
        width:266px;


        @include breakpoint('vp-switch') {
          display: block;
          height:443px;
          width:475px;
        }
    */
    /*

    @include breakpoint('vp-main') {
     @include fluid-property('width', $main-stage-min-width, $main-stage-width, 285px, 475px);
     @include fluid-property('height', $main-stage-min-width, $main-stage-width, 266px, 443px)
    }
    */

    content: "";


    //background-size: 400px 400px;
    background-size: cover;
    background-image: url('../assets/img/polygon_only_bottom_neu.svg');
    background-repeat: no-repeat;


    //content: url('../assets/img/polygon_only_bottom_neu.svg') ;
  }
}
