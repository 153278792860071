.header_navi {
  position: relative;
  right: 0px;
  top: calc(50% + 15px);
}

.header_navi__buttons {
  display: flex;
  align-items: center;
  height:100%;
  right: 0px;
  position: absolute;

  > i {
    @include add_fluid_space('xxs',  'margin', 'left');
  }

}

.header_navi__container {
  background-color: transparent;
  display: block;
  position: absolute;
  overflow: hidden;
  pointer-events: none;

  height: 250px;

  @include fluid-property('width', $mobile-min-width, $main-stage-width, 200px, 300px);

  right: 0px;
  top: 0px;


}

.header_navi__list {
  width: 100%;

  transition: top 0.5s ease;
  background: color('blue-light');
  cursor: pointer;
  list-style: none;
  top:  -20rem;
  position: absolute;
  display: block;
  right: 0px;

  z-index: 1;
}

.header_navi__container.expanded {
  pointer-events: unset;
  .header_navi__list {
    top: 0px;
  }
}


.header_navi__container {
  text-align: left;
  li {

    a {
      display: inline-block;
      padding-left: 10px;
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;

      border-bottom: 1px solid color('blue-bright');

      @include font-medium-18-20();
      text-decoration: none;
      font-weight: 500;
      color: color('text');
    }


    &:hover {
      background-color: color('green');
      a {
        color: color('white');
      }

      a > .header_navi__close__icon {
        color: color('white');
      }
    }

    &:last-child {
      margin-bottom: 10px;
    }
  }
}
.header_navi__close__list_item {
  display: none;
}

.header_navi__close {


  text-align: right;

  @include add_space('xs', 'padding', 'right');
}

.header_navi__close__icon {

  pointer-events: none;
  font-size: 30px !important;
  color: color('green');
}


.header_navi__search {
  @include fluid-type($mobile-min-width, $main-stage-width, 32px, 48px);
  color: color('green');
  cursor: pointer;
  padding: 0px;

  &:hover {
    color: color('blue-bright');
  }

  &:after {
    top: 0px;
    right: 0px;
    width: 50px;
    height: 50px;
    background-color: transparent;
    position: absolute;
    content: "";
  }

}

.header_navi__bookmarks {
  @include fluid-type($mobile-min-width, $main-stage-width, 32px, 48px);
  color: color('green');


  cursor: pointer;
  padding: 0px;


  &:hover {
    color: color('blue-bright');
  }

  &:after {
    top: 0px;
    right: 0px;
    width: 50px;
    height: 50px;
    background-color: transparent;
    position: absolute;
    content: "";
  }

}

.header_navi__toggle  {


  padding: 0px;
  display: flex;
  align-items: center;

  height: 100%;

  user-select: none;
  cursor: pointer;
  @include fluid-type($mobile-min-width, $main-stage-width, 32px, 48px);

  &:before {
    @include add_space('xs', 'margin', 'right');
  }

  &:after {
    top: 0px;
    right: 0px;
    width: 70px;
    height: 100px;
    background-color: transparent;
    position: absolute;
    content: "";
  }

  color: color('green');

  &:hover {
    color: color('blue-bright');
  }
}



