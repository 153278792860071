.profile_card {

  position: relative;
  display: flex;

  @include add_fluid_space('xs','margin', 'top' );
  width: 100%;


}

.profile_card__content {
  display: flex;
  flex: 0 0 100%;

  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  flex-direction: row;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  @include add_fluid_space('s','padding', 'top', 'bottom' );
  background-color: color('white');
  //@include add_fluid_space('m','margin', 'bottom' );
}

.profile_card__image_container {

  @include add_fluid_space('s','margin', 'left', 'right' );

}

.profile_card__data__headline {
  @include add_fluid_space('xxs','padding', 'bottom' );

  > a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: color('green')
    }
  }
}

.profile_card__data {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.profile_card__data__abilities {


}

.profile_card__data__additional {
  @include add_fluid_space('xs','padding', 'top' );
  @include fluid-type($relative-min-width, $main-stage-width, 14px, 18px);
}

.profile_card__data__profile_button {
  display: none;
  align-self: flex-end;


  margin-top: 15px;
  margin-right: 20px;
  //position: absolute;
  //bottom: 0px;

}

.profile_card__data__employer {

  &:after {
    content: "|";
    font-size: 16px;
    color: color('blue-bright');
    margin: 0px 5px 0px 5px;

  }
}
