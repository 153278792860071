.password_recovery {
  margin: 10px;

  .submit_button {
    @include add_fluid_space('s', 'margin', 'top');
    float: right;
  }

  .error {
    color: color('orange');
  }

  .formHint {
    display: none;
  }

  form {

    padding-top: 20px;
    margin: 0px auto;
    max-width: $max-content-width;

    .errorField {
      margin-left: 8px;
      margin-bottom: 5px;
    }

    padding-bottom: 20px;
  }

  @include add_fluid_space('m', 'margin', 'bottom');
}

.password_recover_complete_button,
.password_recovery_confirm_button{

  float:left !important;
}

.password_recovery__intro {

  margin: 0px auto;
  max-width: $max-content-width;

  @include breakpoint('vp-main') {
    @include add_fluid_space('l', 'padding', 'top');
  }

  @include add_fluid_space('s', 'padding', 'bottom');
}

.password_recovery__intro__headline {
  @include add_fluid_space('s', 'margin', 'bottom');
}


.password_recovery__headline {

  @include add_fluid_space('s', 'margin', 'bottom');
}

.password_recovery__text {

}


