.module__text_teaser {
  @include add_fluid_space('ml','margin', 'top' );
  @include add_fluid_space('m','margin', 'bottom' );


  color: color('white');
  background-color: color('dark-green');
}

.module__text_teaser__content {
  @include add_fluid_space('s','padding', 'left', 'right');
  @include add_fluid_space('ml','padding', 'top');
  @include add_fluid_space('xl','padding', 'bottom');
  margin-bottom: 140px; // because of ploygon image height

  position: relative;

  &:after {
    position: absolute;
    display: block;
    right: 10px;
    margin-top: 20px;
    content: url('../assets/img/polygon_top_neu.svg');
  }
}

.module__text_teaser__headline {
  color: color('white');
  @include add_fluid_space('s','padding', 'bottom');
}
