.profile_statistic {

  @include add_fluid_space('xxs', 'margin', 'top');


  max-width: calc(#{$max-content-width} + 20px);
  margin: 0px auto;
  padding: 10px;


  table {
    @include add_fluid_space('xxs', 'margin', 'top');

    border-spacing:0;

    td {
      padding: 5px;
    }

    tr > td:first-child {
      padding-right: 20px;
      font-weight: bold;
    }

    tr:first-child td {

    }
  }
}
