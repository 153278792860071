.generic_modal {

  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 2006; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(#000000,0.7);

  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.generic_modal__content {
  background-color: var(--color-white);
  margin: 15vh auto;
  padding:10px;
  padding-top: 48px;
  width: 95%;
  max-width: 1200px;
  position: relative;

  opacity: 0;
  transform: scale(0.8);
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.generic_modal__content__close_button {


  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  cursor: pointer;

  color: var(--color-blue-text);
  font-size: 1rem;
  float: right;
  clear: both;
  display: flex;
  height: 1rem;
  width: 1rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg{
    width: 1.2rem;
    height: 1.2rem;
  }



  &:hover {
    color: var(--color-hover) !important;

    path{
      fill: var(--color-hover) !important;
    }
  }

}
