.onboarding {
  margin: 10px;
  @include add_fluid_space('xl', 'padding', 'bottom')
}

.onboarding_intro {

  margin: 0px auto;
  max-width: $max-content-width;

  @include breakpoint('vp-main') {
    @include add_fluid_space('l', 'padding', 'top');
  }

  @include add_fluid_space('m', 'padding', 'bottom');
}

.onboarding_last_step {
  @include add_fluid_space('m', 'padding', 'bottom');
}

.onboarding_last_step__button {
  float: right;
  @include add_fluid_space('xl', 'margin', 'top', 'button');
}

.onboarding_step__text {

  @include add_fluid_space('s', 'padding', 'top');
}

.onboarding_form {
  margin: 0px auto;
  max-width: $max-content-width;
  @include add_fluid_space('l', 'padding', 'bottom')
}

label[for="id_self_presentation"] {
  margin-top: 35px;
}

.onboarding_form__next_button {

  @include add_fluid_space('m', 'margin', 'top');
  float: right;

}

.usersuggestion_buttonholder {
  display: flex;
  justify-content: right;
  @include add_fluid_space('m', 'margin', 'top');
  > input:first-child {
    margin-right: 5px;
  }

  > input {
    margin: 0px;
  }
}
