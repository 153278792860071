
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'idb_icons';
    src: url('../font/idb_icons.svg?52403135#idb_icons') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "idb_icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}


.icon-mail:before { content: '\e800'; } /* '' */
.icon-mail-1:before { content: '\e801'; } /* '' */
.icon-facebook:before { content: '\e802'; } /* '' */
.icon-linkedin:before { content: '\e803'; } /* '' */
.icon-instagram:before { content: '\e804'; } /* '' */
.icon-twitter:before { content: '\e805'; } /* '' */
.icon-xing:before { content: '\e806'; } /* '' */
.icon-website:before { content: '\e807'; } /* '' */
.icon-arrow_down:before { content: '\e808'; } /* '' */
.icon-arrow_up:before { content: '\e809'; } /* '' */
.icon-arrow_right:before { content: '\e80a'; } /* '' */
.icon-search:before { content: '\e80b'; } /* '' */
.icon-badge:before { content: '\e80c'; } /* '' */
.icon-profil-light:before { content: '\e80d'; } /* '' */
.icon-social:before { content: '\e80e'; } /* '' */
.icon-bookmark_marker:before { content: '\e80f'; } /* '' */
.icon-close:before { content: '\e811'; } /* '' */
.icon-burger_menu:before { content: '\e812'; } /* '' */
.icon-plus:before { content: '\e813'; } /* '' */
.icon-references:before { content: '\e814'; } /* '' */
.icon-profil:before { content: '\e815'; } /* '' */
.icon-checkbox_off:before { content: '\e816'; } /* '' */
.icon-checkbox_on:before { content: '\e817'; } /* '' */
.icon-radiobutton_off:before { content: '\e818'; } /* '' */
.icon-radiobutton_on:before { content: '\e819'; } /* '' */
.icon-arrow_left:before { content: '\e81a'; } /* '' */
.icon-place:before { content: '\e81b'; } /* '' */
.icon-company:before { content: '\e81c'; } /* '' */
.icon-magnifier:before { content: '\e81d'; } /* '' */
.icon-bookmark_marker_full:before { content: '\e821'; } /* '' */
.icon-bookmark:before { content: '\e822'; } /* '' */
.icon-bookmark_full:before { content: '\e823'; } /* '' */



