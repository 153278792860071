.profile_references {

  margin-top: 40px;

  fieldset {
    border: 0px;

    [id^="all_profile_references_fieldset_toggle"] ~ .profile_references__content {
      display: none;
    }

    [id^="all_profile_references_fieldset_toggle"]:checked {

      ~legend {

      }
      ~ legend > label {
        &:after {
          content: '\e809';
        }
      }
      ~ .profile_references__content {
        display: block;
      }
    }

    legend {

      color: color('white');
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;

      align-content: center;
      cursor: pointer;

      padding: 0px;
      margin: 0;

      border-bottom: 2px solid color('blue');

      .profile_all_references__icon {
        color: color('text-dark');
        @include fluid-type($mobile-min-width, $main-stage-width, 27px, 55px);
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: color('blue', 0.8);
        }
      }

      > label {

        color: color('text-dark');
        @include font-medium-14-18();
        cursor: pointer;
        height: 100%;
        padding: 0px;
        margin: 0;
        margin-left: 0px;
        width: 100%;

        display: flex;
        align-items: center;


        cursor: pointer;

        position: relative;


        &:after {
          font-family: "idb_icons";

          font-style: normal;
          font-weight: normal;
          speak: never;
          position: absolute;
          right: 0px;

          top: 50%;
          transform: translateY(-50%);


          display: inline-block;
          text-decoration: inherit;

          @include fluid-type($mobile-min-width, $main-stage-width, 35px, 50px);

          text-align: center;

          content: '\e808';
        }
      }

    }
  }
}


.profile_reference {

  position: relative;
  .profile_reference__text {
    @include add_fluid_space('xxs', 'padding', 'left' ,'right');
    @include add_fluid_space('xxs', 'padding', 'top' ,'bottom');
  }

  margin-top: 60px;
  margin-bottom: 30px;
  padding-top: 20px;
  background-color: color('blue-light');

  table {
    tr {
      td {
        padding-top: 10px;
      }
      td:first-child {
        padding-right: 10px;
      }
      td:nth-child(2) {
        @include font-copy-small()
      }
    }
  }

 .reference_label {
   @include font-subhead();
 }
}

.reference_divider {

  position: absolute;
  display: flex;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);

  @include fluid-property('width', $mobile-min-width, $main-stage-width, 44px, 53px);
  @include fluid-property('height', $mobile-min-width, $main-stage-width, 50px, 60px);

  background-color: color('green');

  flex-direction: column;
  align-items: center;
  justify-content: center;


  clip-path: polygon(50% 0, 0 24%, 0 76%, 50% 100%, 100% 76%, 100% 24%);
  z-index: 2;
}

.reference_divider__text {
  display: block;
  color: color('white');
  text-align: center;
  @include font-button();

  &:not(.no_image) {
    .edit_text {
      display: block;
    }
    .upload_text {
      display: none;
    }
  }

  &.no_image {
    .edit_text {
      display: none;
    }
    .upload_text {
      display: block;
    }
  }
}

