.search_form_select_box {

  //margin-left: 10px;
  //margin-right: 10px;
  width: 100%;

  h3 {
    text-align: center;
    @include add_space('s', 'padding', 'bottom');
  }
}

.search_form_select {

  outline: 0;
  box-shadow: none;
  border: 0px;
  border-bottom: 2px solid grey;
  background-image: none;
  position: relative;
  cursor: pointer;
  color: color('text');

}


.search_form_select_box__select_wrapper {
  position: relative;

  border-bottom: 2px solid color('blue');
  @include add_fluid_space('s', 'margin', 'bottom', 'top');

  width: 100%;
  height: 50px;
  line-height: 4;

  @include add_space('xxs', 'padding', 'top', 'bottom');

  @include breakpointMax('vp-480') {
    @include add_space('xs', 'margin', 'bottom');
  }


  @include breakpoint('vp-480') {
    width: 48%;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;
  &:hover {
    &:after {
      color: color('green');
    }

  }

  &:after {
    font-family: "idb_icons";
    font-style: normal;
    font-weight: normal;
    pointer-events: none;
    position: absolute;
    right: 0px;
    color: color('text-dark');



    @include fluid-type($mobile-min-width, $main-stage-width, 35px, 50px);

    text-align: center;

    content: '\e808';
  }

}

select.search_form_select_box__select {

  //background-color: yellow;
  flex: 1;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0px;
  background-image: none;
  position: relative;
  cursor: pointer;
  color: color('text');
  @include font-medium-14-18();
  // @include fluid-property('padding', $mobile-min-width, $main-stage-width, 10px, 15px);

  option {
    &:disabled {
      color: lightgrey;
      opacity: 0.5;
    }
  }
}




.search_form_select__form {
  display: flex;
  @include add_space('xs', 'padding', 'left', 'right');
  flex-direction: column;
  max-width: 675px;
  width: 100%;
  justify-content: space-between;
  margin: 0px auto;

  @include breakpoint('vp-480') {
    flex-direction: row;
  }
}
