.module__search_box {
  margin: 0px auto;
  max-width: $max-content-width;
  @include add_fluid_space('ml','margin', 'top' );
  @include add_fluid_space('m','padding', 'bottom' );
}

.module__search_box_main {

  @include add_fluid_space('s','padding', 'left', 'right');

}

.module__search_box_main__headline {

  @include add_fluid_space('s','padding', 'bottom' );
  @include breakpoint('vp-main') {
    text-align: center;
  }
}

.module__search_box_main__text {

  @include add_fluid_space('ml','padding', 'bottom' );
  @include breakpoint('vp-main') {
    text-align: center;
  }
}

.module__search_box_forms__free_text_info {
  font-family: $font-family-headline-serif;
  @include add_fluid_space('m', 'margin', 'top', 'bottom');
  @include fluid-type($relative-min-width, $mobile-min-width, 18px, 34px);

  text-align: center;
}

.module__search_box_forms {

}

.module__search_box_main__form_2nd {

}


