.search_form {

  //margin-left: 10px;
  //margin-right: 10px;
  width: 100%;




  h3 {
    text-align: center;
    @include add_space('s', 'padding', 'bottom');
  }
}


.search_form__input {
  display: block;
  width: 100%;
  color: color('text');
  border:0px;

  &::placeholder {
    color: #646363; //color('placeholder');
  }

  @include breakpoint('vp-480') {
    @include add_space('xxs', 'padding', 'top', 'bottom');

  }

  @include font-medium-14-20();
  position: relative;
}

.search_form__form {
  display: flex;
  flex-direction: row;
  max-width: 675px;
  margin: 0px auto;
  @include add_space('xs', 'padding', 'left', 'right');
}

.search_form__submit_button {

  background-color: unset;
  margin-right: 15px;

  border: none; /* Remove borders */
  //color: color('blue-bright'); /* White text */
  color: color('text-dark');
  padding: 12px 16px; /* Some padding */
  cursor: pointer; /* Mouse pointer on hover */
  padding:0px;
  > i {
    @include fluid-type($mobile-min-width, $main-stage-width, 35px, 50px);

  }

  &:hover {
    color: color('green');
  }
}

.search_form__result_list {
  background-color: color('white');
  position: absolute;
  z-index: 90;
  color: color('text');
  left: 50%;
  transform: translateX(-50%);

  display: block;
  max-width: 635px;
  width: calc(100vw - 20px);
  margin: 0px auto;

  max-height: 350px;
  overflow-y: scroll;

  list-style-type: none;

  --stroke-width: 1px;


  @include breakpoint('vp-480') {
    --stroke-width: 2px;
  }

  border-bottom: var(--stroke-width) solid color('blue-bright');

  &:not(:empty) {
    border-top: var(--stroke-width) solid color('gray');
    border-bottom: var(--stroke-width) solid color('blue-bright');
    @include add_space('xxs', 'padding', 'top', 'bottom');
  }


  > li {
    padding: 0px 0px 0px 10px;
    margin: 0px;

    margin: 5px 0px 5px 0px;
  }

  .sublist_label {
    font-weight: bold;
  }

  ul.sublist {

    li {
      list-style: none;
      text-align: left;
      @include add_space('xxs', 'padding', 'top', 'bottom');
      @include add_space('xs', 'padding', 'left', 'right');

      a {
        @include font-medium-14-20();
        color: color('text');
        text-decoration: none;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

