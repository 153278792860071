footer {
  background-color: color('footer-gray');
  @include add_fluid_space('s','padding', 'top' );
  margin-top:auto;
  /*position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
*/
}

.footer__inner {
  @include add_fluid_space('s','padding', 'left', 'right');
  margin: 0px auto;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
}

.footer__data {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;
  @include breakpoint('vp-main') {

    justify-content: flex-start;
  }

}

.footer__logo {

  display: none;

  @include breakpoint('vp-main') {
    height: 90px;
    width: 240px;
    z-index: 0;
    display: flex;



    background-image: url('../assets/img/media-innovators-logo.svg');
    background-repeat: no-repeat;
    background-position: 0px 0px;



  }
}

.footer__contact {
  color: color('text');
  line-height: 1.5rem;



  font-family: $font-family;
  @include fluid-type($relative-min-width, $main-stage-width, 14px, 18px);

  @include breakpoint('vp-main') {
    @include add_space('l', 'margin', 'left');
    @include add_space('m', 'margin',  'right');
  }
}

.footer__meta {


}


.footer__meta__analytics_hint {
  color: color('text');
  flex-basis: 100%;

  @include fluid-type($relative-min-width, $main-stage-width, 10px, 14px);
  @include add_fluid_space('s','padding', 'top', 'bottom' );

  display: flex;
  flex-direction: column;
  @include breakpoint('vp-main') {
    align-items: flex-start;
  }
}

.footer__meta__analytics_hint__text {

  @include breakpoint('vp-main') {
    margin-right: 10px;
  }
}

.footer__meta__links {

  justify-items: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  list-style: none;

  @include breakpoint('vp-main') {
   text-align: left;
  }


  @include fluid-type($relative-min-width, $main-stage-width, 14px, 18px);

  a {
    text-decoration: none;
  }

    li:not(:first-child) {
      margin-top: 10px;
    }

}

.footer__social {
  border-top: 1px solid color('green');
}

.footer__social__links {

  @include add_fluid_space('s','padding', 'top', 'bottom' );

  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  list-style: none;



  li {
    margin-left: 10px;
    margin-right: 10px;

    i {
      @include fluid-type($relative-min-width, $main-stage-width, 18px, 24px);
    }
  }

  @include breakpoint('vp-main') {
    justify-content: flex-end;

    li {
      margin-left: 15px;
      margin-right: 15px;

      i {
        @include fluid-type($relative-min-width, $main-stage-width, 18px, 20px);
      }
    }
  }



}
