.module__innovators_teaser {
  @include add_fluid_space('ml','margin', 'top' );
  @include add_fluid_space('m','margin', 'bottom' );

  display: flex;
  flex-direction: column;
  align-items: center;


}

.module__innovators_teaser__headline {
  @include add_fluid_space('s','padding', 'left', 'right');

  @include breakpoint('vp-main') {
    text-align: center;
  }
}

.module__innovators_teaser__cards {

  max-width: 1040px;

  width: 100%;
  grid-template-columns: 1fr;
  display: grid;

  @include breakpoint('vp-switch') {
    grid-template-columns: 1fr 1fr;
  }
  @include add_fluid_space('xs','padding', 'bottom' );


  &.module__innovators_teaser__cards__single {
    //align-items: center;
    grid-template-columns: 1fr;
    max-width: 520px;
    width: 100%;

    > .module__innovators_teaser__cards__card {
      > .profile_card__content {

        @include add_fluid_space('reset','padding', 'left', 'right' );
        padding-right: 0px;
        margin-left: auto;
        margin-right: auto;

      }

    }
  }

}

.module__innovators_teaser__cards__card {

  > div {
    height: 100%;
  }

  //flex: 0 50%;
  @include breakpoint('vp-main') {
    @include add_fluid_space('xxs','padding', 'left', 'right' );
  }

  @include breakpointMax('vp-480') {
    // only show the first to elements on mobile
    &:nth-of-type(n+3) {
      display:none;
    }
  }


}
