.search_result {
  @include add_fluid_space('ml','margin', 'top' );
  @include add_fluid_space('m','margin', 'bottom' );

  display: flex;
  flex-direction: column;
  align-items: center;

}

.search_result__no_entries {
  margin: 0px auto;
  display: block;
  max-width: 675px;
}

.search_result__cards {

  max-width: 1040px;
  width: 100%;

  grid-template-columns: 1fr;
  display: grid;


  @include breakpoint('vp-switch') {
    grid-template-columns: 1fr 1fr;
  }

  @include add_fluid_space('xs','padding', 'bottom' );

  &.search_result__cards__single {

    grid-template-columns: 1fr;
    max-width: 520px;
    width: 100%;


    > .search_result__cards__card {
      > .profile_card__content {
        @include add_fluid_space('reset', 'padding', 'left', 'right');
        margin-left: auto;
        margin-right: auto;
        }
    }
  }
}


.search_result__cards__card {

  > div {
    height: 100%;
  }
  @include breakpoint('vp-main') {
    @include add_fluid_space('xxs','padding', 'left', 'right' );
  }

}


.module__search_pagination {

  margin: 0px auto;
  display: block;

  font-family: $font-family;
  font-size: 18px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  li {
    float: left;
    display: block;
    text-align: center;
    margin: 10px;

  }
  li a {
    text-decoration: none;
  }

}
