

@mixin content-block-margins {
  // padding to other sections

  margin-top: map_get($v-space-map-mobile, 's');
  margin-bottom: map_get($v-space-map-mobile, 's');

  @include breakpoint('vp-main') {
    margin-top: map_get($v-space-map, 's');
    margin-top: map_get($v-space-map, 's');
  }
}


@mixin content-horizontal-margins($size:'xxs') {
  margin-left: map_get($h-space-map-mobile, $size);
  margin-right: map_get($h-space-map-mobile, $size);

  @include breakpoint('vp-full') {
    margin-left: 0;
    margin-right: 0;
  }
}


@mixin content-block-padding {

  padding-bottom: map_get($v-space-map-mobile, 'xs');
  padding-top: map_get($v-space-map-mobile, 'xs');

  @include breakpoint('vp-main') {
    padding-bottom: map_get($v-space-map, 'xs');
    padding-top: map_get($v-space-map, 'xs');
  }
}


@mixin add_space($size, $space_type:'padding', $directions...) {

  @for $i from 0 to length($directions) {

    $dir: nth($directions, $i + 1);
    @if $dir == 'top' or $dir == 'bottom'{
      #{$space_type}-#{$dir}: map_get($v-space-map-mobile, $size);
    }
    @if $dir == 'left' or $dir == 'right' {
      #{$space_type}-#{$dir}: map_get($h-space-map-mobile, $size);
    }
  }

  @include breakpoint('vp-main') {
    @for $i from 0 to length($directions) {

      $dir: nth($directions, $i + 1);
      @if $dir == 'top' or $dir == 'bottom' {
        #{$space_type}-#{$dir}: map_get($v-space-map, $size);
      }
      @if $dir == 'left' or $dir == 'right' {
        #{$space_type}-#{$dir}: map_get($h-space-map, $size);
      }
    }
  }
}



/* fluid versions */

@mixin content-block-margins-fl {
  // padding to other sections
  @include fluid-vertical-spacer('s', 'bottom', 'margin');
  @include fluid-vertical-spacer('s', 'top', 'margin');
  //background-color: red;

}

@mixin content-block-padding-fl {
  // padding to other sections
  @include fluid-vertical-spacer('xs', 'bottom' );
  @include fluid-vertical-spacer('xs', 'top');
  //background-color: red;

}


