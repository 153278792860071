
.profile_card__bookmark_button {

  position: absolute;
  right: 27px;
  z-index:10;
  height: 40px !important;

  .profile__bookmark_icon {
    font-size: 40px !important;
  }
}

.profile__bookmark_button {
  display: block;

  .profile__bookmark_icon {
    font-size: 50px;
    cursor: pointer;
    color: color('orange');

    $hoverBgColor: #ffdd56;
    &:hover {
      color: $hoverBgColor;
    }

    &:before {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: -1px;

    }
  }

}
