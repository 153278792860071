.confirm_modal_content {
  background-color: color('white');

  margin:auto;
  width: 100%;

  max-width: unset;
  border-radius: 2px;

  padding:15px;

  max-width: 350px;

  h1 {
    margin-bottom: 20px;
  }

  .buttons {
    margin-top: 10px;
    display: flex;
    justify-content: center;

    > div {
      margin: 5px;
    }

  }

  span.project_name {
    font-weight: bold;
  }


}
