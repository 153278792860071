#alert-container {

  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3000;
  top: 50px;

  .alert {

    display: flex;
    justify-content: center;
    padding: 10px 18px 10px 18px;
    margin: 5px;
    border-radius: 3px;

    @include font-copy-small();
    color: color('white');

    &.success {
      background-color: #268826;
    }

    &.error {
      background-color: color('warning');
    }
  }
}
