.profile_edit {
  @include add_fluid_space('l', 'margin', 'top');
  margin: 0px 10px 0px 10px;
}

.profile_edit__form {

  margin: 0px auto;
  max-width: $max-content-width;

}

.profile_edit__image {
 margin: 0px auto;
  
}

.profile_edit__email_field {
  margin-bottom: 25px;
}

.profile_edit__headline {
  margin-bottom: 30px;
}

.profile_edit_form__helpt_text {
  @include add_fluid_space('s', 'margin', 'bottom');
}

.profile_edit_form__contact_rules_label {
  @include add_fluid_space('s', 'margin', 'bottom', 'top');
}

.profile_edit__fieldset_personal_data {
  border: 0px;
  @include add_fluid_space('m', 'margin', 'bottom');
}

.profile_edit__fieldset_accordion {

  @include add_fluid_space('s', 'padding', 'top');
}

