

.reference__fieldset_accordion {

  border: 0px;

  legend {
    background-color: color('blue-light');
    color: color('green');
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 50px;

    align-content: center;
    cursor: pointer;

    padding: 0px;
    padding-left: 10px;
    margin: 0;

    label {
      color: color('green');
      margin-left: 15px;

    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: color('blue');
      }
    }

    > label {

      cursor: pointer;
      height: 100%;
      padding: 0px;

      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      @include font-medium-14-18();

      position: relative;

      &:after {
        font-family: "idb_icons";

        font-style: normal;
        font-weight: normal;
        speak: never;
        position: absolute;
        right: 0px;

        top: 50%;
        transform: translateY(-50%);


        display: inline-block;
        text-decoration: inherit;

        @include fluid-type($mobile-min-width, $main-stage-width, 35px, 50px);

        text-align: center;

        content: '\e808';
      }
    }

  }

  .reference_image_field {
    margin-top: 30px;
  }

}

.reference__fieldset_accordion__content {
  .reference_form_button_container {
    display: flex;
    justify-content: center;
    @include add_fluid_space('s', 'margin', 'top');
    > div {
      margin: 5px;
      height: 35px;
      padding: 10px;
    }

    div.reference_form_submit_button {
      &.flash_success {
        animation: flash_success .5s;
      }
      &.flash_error {
        animation: flash_error .5s;
      }

      @keyframes flash_success {
        50% {
          background: green;
        }
        100% {
          background: color('blue');
        }
      }

      @keyframes flash_error {
        50% {
          background: red;
        }
        100% {
          background: color('blue');
        }
      }
    }

    div.reference_form_delete_button {
      background-color: color('blue-light');

    }

  }
}


[id^="reference__fieldset_accordion_"] ~ .reference__fieldset_accordion__content {
  display: none;
}

[id^="reference__fieldset_accordion_"]:checked {

  ~ legend > label {
    &:after {
      content: '\e809';
    }
  }


  ~ .reference__fieldset_accordion__content {
    display: block;
    @include add_fluid_space('s', 'padding', 'top');
    @include add_fluid_space('m', 'padding', 'bottom');
  }
}



.reference__fieldset_icon {

  margin-top: 4px;
  display: flex;
  //left: 50%;
  //transform: translate(-50%, -50%);

  //@include fluid-property('width', $mobile-min-width, $main-stage-width, 44px, 53px);
  //@include fluid-property('height', $mobile-min-width, $main-stage-width, 50px, 60px);

  width: 40px;
  height: 42px;

  //height: 100%;

  background-color: color('green');

  flex-direction: column;
  align-items: center;
  justify-content: center;


  clip-path: polygon(50% 0, 0 24%, 0 76%, 50% 100%, 100% 76%, 100% 24%);
  z-index: 2;
}

.reference__fieldset_icon__text {
  display: block;
  color: color('white');
  text-align: center;
  @include font-button();

  &:not(.no_image) {
    .edit_text {
      display: block;
    }
    .upload_text {
      display: none;
    }
  }

  &.no_image {
    .edit_text {
      display: none;
    }
    .upload_text {
      display: block;
    }
  }
}
