.small_button {

  height: 20px;
  font-size: 14px;
  color: color('white');
  background-color: color('green');
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  padding: 0px 20px 0px 20px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: color('green', 0.8);
    color: color('white');
  }


}
