.headline_centered {
  text-align: center;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Links */

a {

  white-space:nowrap;
  color: color('green');
  text-decoration: underline;
  display: inline-block;
  outline: 0;
  @media (hover: hover) {
    &:hover {
      color: color('blue');
    }
  }
  -webkit-tap-highlight-color: rgba(0,0,0,0);


  /*&:focus {
    color: color('light-blue');
  }*/
}
a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}


