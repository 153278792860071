.module__hero_teaser {

  background-color: color('text-dark');

  //background-image: url('../assets/img/headerbild@3x.jpg');
  // background-image: url('../assets/img/headerbild.jpg');
  background-repeat: no-repeat;
  background-position: 0px center;
  background-size: cover;
  //background-attachment: fixed;

  @include add_fluid_space('sm','padding', 'left', 'right');
  @include add_fluid_space('m','padding', 'top' );
  @include add_fluid_space('m','padding', 'bottom' );

}
.module__hero_teaser_copy {
  @include font-copy-hero();
  color: color('white');

  text-align: center;

  p:nth-last-of-type {
    @include add_fluid_space('m','padding', 'bottom' );
  }
}

.module__hero_teaser__logo {
  width: auto;
  height: 156px;
  @include add_fluid_space('m','margin', 'top' );
  @include add_fluid_space('s','padding', 'bottom' );
  @include fluid-property("height",  375px, 1370px, 82px, 156px);
  background-image: url('../assets/img/media-innovators-logo-white.svg');
  background-repeat: no-repeat;
  background-position: center;
}
