.error_page__intro {

  margin: 0px auto;
  max-width: $max-content-width;

  @include breakpoint('vp-main') {
    @include add_fluid_space('l', 'padding', 'top');
  }

  @include add_fluid_space('m', 'padding', 'bottom');
}
