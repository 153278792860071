/*------------------------------------*\
    $FUNCTIONS
\*------------------------------------*/

@function calcREM($px, $context: $font-size-base) {
  $rem: $px / $context;
  @return #{$rem}rem;
}

@function color($key, $opacity: 1) {
  @if map-has-key($colors, $key) {

    $col: map-get($colors, $key);

    @if $opacity < 1 {

      $col: rgba($col, $opacity);
    }

    @return $col;
  }

  @warn "Unknown `#{$key}` in $colors.";
  @return null;
}


@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}
