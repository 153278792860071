#profile_bookmarks_result {

  @include add_fluid_space('ml','margin', 'top' );
  @include add_fluid_space('m','margin', 'bottom' );

  display: flex;
  flex-direction: column;
  align-items: center;

}

.profile_bookmarks_info {
  margin-top: 40px;
  padding: 20px;
  border-top: 1px solid grey;
  text-align: center;
  line-height: calcREM(20px);
}

.profile_bookmarks_result__no_results {
  margin: 0px auto;
  display: none;
  max-width: 675px;
}

.profile_bookmarks_result__cards {

  max-width: 1040px;
  width: 100%;

  grid-template-columns: 1fr;
  display: grid;


  @include breakpoint('vp-switch') {
    grid-template-columns: 1fr 1fr;
  }

  @include add_fluid_space('xs','padding', 'bottom' );

  &.profile_bookmarks_result__cards__single {

    grid-template-columns: 1fr;
    max-width: 520px;
    width: 100%;


    > .profile_bookmarks_result__cards__card {
      > .profile_card__content {
        @include add_fluid_space('reset', 'padding', 'left', 'right');
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.profile_bookmarks_result__card_remove_btn {
  display: block;
  width: 28px;
  cursor: pointer;
  height: 28px;
  margin-top: 5px;
  margin-right: 5px;
  position: absolute;

  &:hover {
    color: color('green');
  }
}

.profile_bookmarks_result__cards__card {

  justify-content: flex-end;

  .profile_card__content {
    height: 100%;
  }
  @include breakpoint('vp-main') {
    @include add_fluid_space('xxs','padding', 'left', 'right' );
  }

}

