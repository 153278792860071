.back_button {

  display: flex;
  flex-direction: row;
  align-items: center;

  text-decoration: none;
  color: color('text-dark');
  @include font-medium-14-18();

  &:hover {
    .back_button__icon {
      color: color('green');
    }
  }

  @include add_fluid_space('l', 'margin', 'bottom');
  @include add_fluid_space('xxs', 'margin', 'top');

}

.back_button__icon {

  @include fluid-type($mobile-min-width, $main-stage-width, 27px, 55px);
  color: color('blue-bright');
}

.back_button__label {


}
