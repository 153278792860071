.similar_profiles {
  @include add_fluid_space('ml','margin', 'top' );
  @include add_fluid_space('m','margin', 'bottom' );

  display: flex;
  flex-direction: column;
  align-items: center;

}

.similar_profiles__cards {

  max-width: 1040px;
  width: 100%;

  grid-template-columns: 1fr;
  display: grid;


  @include breakpoint('vp-switch') {
    grid-template-columns: 1fr 1fr;
  }

  @include add_fluid_space('xs','padding', 'bottom' );



  &.similar_profiles__cards__single {

    grid-template-columns: 1fr;
    max-width: 520px;
    width: 100%;



    > .similar_profiles__cards__card {
      > .profile_card__content {
        @include add_fluid_space('reset', 'padding', 'left', 'right');
        margin-left: auto;
        margin-right: auto;
        }
    }
  }
}


.similar_profiles__cards__card {

  > div {
    height: 100%;
  }
  @include breakpoint('vp-main') {
    @include add_fluid_space('xxs','padding', 'left', 'right' );
  }

}

