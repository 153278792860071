.idb_form {

  display: block;

  .errorField {
    color: color('warning-text');
    margin-bottom: 5px;
    margin-left: 8px;

    ~label  {
      color: color('warning-text');
      .asteriskField
      {
        color: color('warning-text');
      }
    }

  }

  #div_id_category.ctrlHolder.error {
    border: 2px solid color('warning');
    padding: 10px;
    border-radius: 5px;
  }

  .ctrlHolder.error:not(#div_id_category) {
    input[type=text], input[type=email], input[type=password], input[type=number], textarea {
      background-color: color('warning');
      border-bottom: 2px solid color('warning-text');
    }
  }


  input[type=text], input[type=email], input[type=password], input[type=number], textarea {
    background-color: color('blue-light');
    border: 0px;
    border-bottom: 2px solid color('blue');




    @include fluid-property('padding', $mobile-min-width, $main-stage-width, 10px, 15px);
    color: #666;
    width: 100%;

    @include font-copy-small();

    &:read-only {
      background-color: color('gray');
      border-bottom: 2px solid #bebebe;
    }
  }

  input[type=submit] {
    -webkit-appearance: none !important;
    appearance: none;
    border-radius: 0px;
  }


  input[type=checkbox] {

    color: color('green');
    position: relative;

  }

  fieldset {
    border: 0px;
  }

  textarea {
    height: 120px;
  }

  .textara_char_count {
    font-size: 10px;
    display: block;
    width: 100%;
    text-align: right;
  }


  .ctrlHolder {
    margin-top: 15px;
  }

  label {
    margin-left: 8px;
    padding-bottom: 2px;
    display: block;
    @include font-subhead();
    color: color('text');

    span.asteriskField {
      color: color('blue');
      margin-left: 5px;
    }
  }

  input:read-only {
    background-color: color('gray');
  }

  div.ctrlHolder.checkbox {
    display: flex;
    flex-direction: row;

    > input {
      display: none;

      &:checked + label {

        &:after {
          content: '\e817';
        }
      }
    }



    > label {
      cursor: pointer;
      @include font-copy-small();
      margin: 0px;

      width: 100%;

      position: relative;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: color('green');
        }
      }

      &:after {
        position: absolute;
        right: 0px;
        font-family: "idb_icons";
        width: 24px;
        @include fluid-type($mobile-min-width, $main-stage-width, 16px, 24px);
        content: '\e816';

      }


    }
  }
}
.profile_image_field {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include add_fluid_space('m', 'margin', 'bottom');
}


.reference_image_field {
  display: flex;
}

.idb_form__accordion_fieldset {

  border: 0px;

  legend {
    background-color: color('green');
    color: color('white');
    width: 100%;
    display: block;
    flex-direction: row;
    height: 50px;

    align-content: center;
    cursor: pointer;

    padding: 0px;
    margin: 0;

    label {
      color: color('white');

    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: color('green', 0.8);
      }
    }

    > label {

      cursor: pointer;
      height: 100%;
      padding: 0px;
      margin: 0;

      margin-left: 0px;
      width: calc(100% - 55px);
      display: flex;
      flex-direction: row;
      align-items: center;
      //@include fluid-property('height', $mobile-min-width, $main-stage-width, 35px, 60px);
      @include font-medium-14-18();

      @include add_fluid_space('s', 'padding', 'left' );

      position: relative;

      &:after {
        font-family: "idb_icons";

        font-style: normal;
        font-weight: normal;
        speak: never;
        position: absolute;
        right: 0px;

        top: 50%;
        transform: translateY(-50%);


        display: inline-block;
        text-decoration: inherit;

        @include fluid-type($mobile-min-width, $main-stage-width, 35px, 50px);

        text-align: center;

        content: '\e808';
      }
    }

  }

}

.idb_form__accordion_fieldset__icon {
  //position: absolute;
  display: block;
  pointer-events: none;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  text-align: center;
  height: auto;
  float: left;
  &:before {
    margin-left: 0px;
    @include fluid-type($mobile-min-width, $main-stage-width, 35px, 50px);
  }




}


[id^="idb_form__accordion_fieldset_"] ~ .idb_form__accordion_fieldset__content {
  display: none;
}

[id^="idb_form__accordion_fieldset_"]:checked {

  ~ legend > label {
    &:after {
      content: '\e809';
    }
  }


  ~ .idb_form__accordion_fieldset__content {
    display: block;
    @include add_fluid_space('m', 'padding', 'bottom');
  }
}


fieldset {
  margin: 10px 0 10px 0;

}


