/*
  Fluid typography mixin
  https://css-tricks.com/snippets/css/fluid-typography/

 */


@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        $new_size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
        font-size: clamp($min-font-size, $new_size, $max-font-size);
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin font-button {
  font-family: $font-family-headline;
  font-weight: bold;
  @include fluid-type($mobile-min-width, $main-stage-width, 16px, 20px);
}

@mixin font-subhead {
  font-family: $font-family-headline;
  //font-size: calcREM(34px);

  @include fluid-type($mobile-min-width, $main-stage-width, 14px, 18px);
}

@mixin font-headline-2 {
  font-family: $font-family-headline;
  //font-size: calcREM(34px);

  @include fluid-type($mobile-min-width, $main-stage-width, 30px, 44px);
}

@mixin font-headline-3 {
  font-family: $font-family-headline;
  //font-size: calcREM(34px);

  @include fluid-type($mobile-min-width, $main-stage-width, 20px, 28px);
}

@mixin font-profile-card-name {
  font-family: $font-family-headline;
  font-size: 20px;
}


@mixin font-headline-serif {
  font-family: $font-family-headline-serif;
  //font-size: calcREM(34px);

  @include fluid-type($relative-min-width, $mobile-min-width, 18px, 34px);

  @include breakpoint('vp-375'){
    @include fluid-type($mobile-min-width, $main-stage-width, 34px, 60px);
  }

}


@mixin font-copy-hero {
  font-family: $font-family;
  //font-size: calcREM(34px);

  @include fluid-type($relative-min-width, $main-stage-width, 24px, 44px);
/*
  @include breakpoint('vp-375'){
    @include fluid-type($mobile-min-width, $mobile-max-width, 24px, 34px);
  }

  @include breakpoint('vp-main'){
    @include fluid-type($main-stage-min-width, $main-stage-width, 24px, 44px);
  }
  */

}


@mixin font-copy {
  font-family: $font-family;
  //font-size: calcREM(34px);
  line-height: calcREM(30px);

  @include fluid-type($relative-min-width, $mobile-min-width, 14px, 18px);

  @include breakpoint('vp-375'){
    @include fluid-type($mobile-min-width, $mobile-max-width, 18px, 22px);
  }

}

@mixin font-copy-small {
  font-family: $font-family-regular;
  @include fluid-property('line-height', $mobile-min-width, $main-stage-width, 20px, 24px);
  @include fluid-type($mobile-min-width, $main-stage-width, 14px, 18px);
}

@mixin font-copy-big {
  font-family: $font-family;
  @include fluid-property('line-height', $mobile-min-width, $main-stage-width, 28px, 30px);
  @include fluid-type($mobile-min-width, $main-stage-width, 18px, 22px);

}

@mixin font-medium-14-20 {
  font-family: $font-family;
  //font-size: calcREM(34px);
  font-weight: 500;

  @include fluid-type($relative-min-width, $main-stage-width, 14px, 20px);

}

@mixin font-medium-14-18 {
  font-family: $font-family;
  //font-size: calcREM(34px);
  font-weight: 500;

  @include fluid-type($relative-min-width, $main-stage-width, 14px, 18px);

}


@mixin font-medium-18-20 {
  font-family: $font-family;
  font-weight: 500;
  @include fluid-type($relative-min-width, $main-stage-width, 18px, 20px);

}
