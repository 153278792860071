[class^="icon-ml_"]:before,
[class*=" icon-ml_"]:before {
  font-family: "MediaLab_Icon" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: text-bottom;

}

.icon-ml_facebook:before { content: '\e800'; } /* 'î €' */
.icon-ml_medium:before { content: '\e801'; } /* 'î ' */
.icon-ml_linkedin:before { content: '\e802'; } /* 'î ‚' */
.icon-ml_twitter:before { content: '\e803'; } /* 'î ƒ' */
.icon-ml_envelope:before { content: '\e804'; } /* 'î „' */
.icon-ml_handclap:before { content: '\e805'; } /* 'î …' */
.icon-ml_search:before { content: '\e806'; } /* 'î †' */
.icon-ml_paper-plane:before { content: '\e807'; } /* 'î ‡' */
.icon-ml_growth:before { content: '\e808'; } /* 'î ˆ' */
.icon-ml_rocket:before { content: '\e809'; } /* 'î ‰' */
.icon-ml_lightbulb:before { content: '\e80a'; } /* 'î Š' */
.icon-ml_pin:before { content: '\e80b'; } /* 'î ‹' */
.icon-ml_star:before { content: '\e80c'; } /* 'î Œ' */
.icon-ml_image:before { content: '\e80d'; } /* 'î ' */
.icon-ml_binoculars:before { content: '\e80e'; } /* 'î Ž' */
.icon-ml_thumb-up:before { content: '\e80f'; } /* 'î ' */
.icon-ml_hand-love:before { content: '\e810'; } /* 'î ' */
.icon-ml_clipboard-check:before { content: '\e811'; } /* 'î ‘' */
.icon-ml_bot:before { content: '\e812'; } /* 'î ’' */
.icon-ml_unicorn:before { content: '\e813'; } /* 'î “' */
.icon-ml_media-house:before { content: '\e814'; } /* 'î ”' */
.icon-ml_idea-outofthebox:before { content: '\e815'; } /* 'î •' */
.icon-ml_muscle:before { content: '\e816'; } /* 'î –' */
.icon-ml_calender:before { content: '\e817'; } /* 'î —' */
.icon-ml_house:before { content: '\e818'; } /* 'î ˜' */
.icon-ml_house-ballons:before { content: '\e819'; } /* 'î ™' */
.icon-ml_clock:before { content: '\e81a'; } /* 'î š' */
.icon-ml_clock-deadline:before { content: '\e81b'; } /* 'î ›' */
.icon-ml_ticket:before { content: '\e81c'; } /* 'î œ' */
.icon-ml_coaching-two-people:before { content: '\e81d'; } /* 'î ' */
.icon-ml_coaching-whiteboard:before { content: '\e81e'; } /* 'î ž' */
.icon-ml_play-circle:before { content: '\e81f'; } /* 'î Ÿ' */
.icon-ml_phone-speech:before { content: '\e820'; } /* 'î  ' */
.icon-ml_phone-likes:before { content: '\e821'; } /* 'î ¡' */
.icon-ml_newspaper:before { content: '\e822'; } /* 'î ¢' */
.icon-ml_notebook:before { content: '\e823'; } /* 'î £' */
.icon-ml_clincking-glasses:before { content: '\e824'; } /* 'î ¤' */
.icon-ml_tada:before { content: '\e825'; } /* 'î ¥' */
.icon-ml_moneybag:before { content: '\e826'; } /* 'î ¦' */
.icon-ml_design:before { content: '\e827'; } /* 'î §' */
.icon-ml_coffee:before { content: '\e828'; } /* 'î ¨' */
.icon-ml_group:before { content: '\e829'; } /* 'î ©' */
.icon-ml_user-inlove:before { content: '\e82a'; } /* 'î ª' */
.icon-ml_user-comments:before { content: '\e82b'; } /* 'î «' */
.icon-ml_microphone:before { content: '\e82c'; } /* 'î ¬' */
.icon-ml_vr-glasses:before { content: '\e82d'; } /* 'î ­' */
.icon-ml_ai:before { content: '\e82e'; } /* 'î ®' */
.icon-ml_hand-molecule:before { content: '\e82f'; } /* 'î ¯' */
.icon-ml_speechbubble:before { content: '\e830'; } /* 'î °' */
.icon-ml_progress:before { content: '\e831'; } /* 'î ±' */
.icon-ml_development:before { content: '\e832'; } /* 'î ²' */
.icon-ml_laptop:before { content: '\e833'; } /* 'î ³' */
.icon-ml_envelope-mail:before { content: '\e834'; } /* 'î ´' */
