.image_preview_widget {

  position: relative;



  cursor: pointer;

  &:hover {
    .image_preview_widget__preview_image {
      opacity: 0.4;
    }

    .image_preview_widget__tools {
      display: flex;
    }
  }
}


  .image_preview_widget__tools {

    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include fluid-property('width', $mobile-min-width, $main-stage-width, 88px, 106px);
    @include fluid-property('height', $mobile-min-width, $main-stage-width, 100px, 120px);

    background-color: color('blue');

    flex-direction: column;
    align-items: center;
    justify-content: center;


    clip-path: polygon(50% 0, 0 24%, 0 76%, 50% 100%, 100% 76%, 100% 24%);
    z-index: 2;
  }

  .image_preview_widget__tool__show {
    display: flex;
  }

  .image_preview_widget__tools__text {
    display: block;
    color: color('text-dark');
    text-align: center;
    @include font-button();

    &:not(.no_image) {
      .edit_text {
        display: block;
      }
      .upload_text {
        display: none;
      }
    }

    &.no_image {
      .edit_text {
        display: none;
      }
      .upload_text {
        display: block;
      }
    }
  }

.image_preview_widget[data-image-type="profile"] {
  .image_preview_widget__preview_image {

    @include fluid-property('width', $mobile-min-width, $main-stage-width, 135px, 382px);
    @include fluid-property('height', $mobile-min-width, $main-stage-width, 147px, 426px);

  }
}

.image_preview_widget[data-image-type="reference"] {

  margin: 0px auto;
  padding: 0;

  width: 100%;
  max-width: 600px;

  .image_preview_widget__preview_image {


     width: 100%;
     height: 100%;


   // @include fluid-property('width', $mobile-min-width, $main-stage-width, 225px, 675px);
   // @include fluid-property('height', $mobile-min-width, $main-stage-width, 150px, 450px);

  }
}

