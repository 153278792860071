.ability_pill {
  display: inline-flex;

  height: 20px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;

  margin-right: 10px;
  margin-top: 10px;
  align-items: center;

  font-size: 14px;

  &:hover {
    opacity: 0.8;
  }
}

/* apply pill colors */
$color-list: (
    'green' color('green'),
    'blue' color('blue')
);

[class^="ability_pill__bg-color__"], [class*=" ability_pill__bg-color__"] {
  // default pill color
  background-color: color('blue');
}

@each $value in $color-list {
  .ability_pill__bg-color__#{nth($value, 1)} {
    background-color: #{nth($value, 2)};
  }
}

.ability_pill__name {
  color: color('white');
  user-select: none;
  font-family: $font-family-regular;
}

.ability_pill__link {
  text-decoration: none;
  color: color('white');
  font-family: $font-family-regular;


  &:hover {
    color: color('white');
  }
}

.ability_pill__mode_card .ability_pill__link {
  font-size: 14px;
}

.ability_pill__mode_profile, .ability_pill__mode_edit {

  @include fluid_property('height', $mobile-min-width, $main-stage-width, 20px, 25px);

  .ability_pill__name {
    @include fluid-type($mobile-min-width, $main-stage-width, 14px, 18px);
  }
}

.ability_pill__mode_edit {
  padding-left: 0px;
}
