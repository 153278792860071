.reference_image {
  display: flex;
  // align-items: stretch;
  position: relative;
  width: 100%;
}

.reference_image__link {
  display: flex;
  &:hover {
    .reference_image {
      background-color: color('green', 0.8);
    }

    .reference_image__thumb {
      background-color: unset;
    }
  }
}

.reference_image__normal {
  @include fluid-property('width', $mobile-min-width, $main-stage-width, 300px, 600px);
  @include fluid-property('height', $mobile-min-width, $main-stage-width, 200px,400px);
}

.reference_image__thumb {

}

.reference_image_container {
  width: 100%;
  aspect-ratio: 600/400;
  contain: content;
  object-fit: cover;
  background-color: color('green');
}

.reference_image__image {

  width: 100%;
  height: 100%;
  object-fit: cover;

    &:after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: color('green');
      font-family: 'Helvetica';
      font-weight: 300;
      line-height: 2;
      text-align: center;
      content: attr(alt);
    }

}
