.submit_button {
  @include fluid-property('height', $mobile-min-width, $main-stage-width, 35px, 45px);
  white-space:nowrap;
  background-color: color('blue');
  color: color('text-dark');
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  padding: 0px 20px 0px 20px;
  text-decoration: none;
  cursor: pointer;
  border: 0px;

  @include font-button();

  &:hover {
    background-color: color('green', 0.8);
    color: color('white');
  }
}
