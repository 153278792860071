
@import "generic/functions";
@import "generic/variables";
@import "generic/mixins/mixins";
@import "generic/mixins/common";
@import "generic/mixins/typo_mixins";
@import "generic/reset";
@import "generic/fonts";

@import "base/typo";
@import "base/icons";
@import "base/medialab_icons";

/* Components */
@import "components/idb_form";

/* MODULES */
@import "organisms/modules/hero_teaser";
@import "organisms/modules/text_teaser";
@import "organisms/modules/news_box";
@import "organisms/modules/login_modal";
@import "organisms/modules/search_box";
@import "organisms/modules/image_text_teaser_box";
@import "organisms/modules/innovators_teaser";
@import "organisms/modules/headline_module";
@import "organisms/modules/page_header";
@import "organisms/modules/page_header_polygon";

@import "partials/header";
@import "partials/header_navi";
@import "partials/footer";
@import "partials/home";

/* Atoms */
@import "atoms/icon";

/* molecules */
@import "molecules/buttons/icon_link";
@import "molecules/profile_card";
@import "molecules/profile/profile_image";
@import "molecules/reference/reference_image";
@import "molecules/reference/profile_reference";
@import "molecules/reference/reference_fieldset";

@import "molecules/profile/profile_data_list_item";
@import "molecules/profile/profile_meta";
@import "molecules/buttons/_profile_bookmark_button";

@import "molecules/profile/profile_edit_meta";
@import "molecules/profile/profile_statistic";
@import "molecules/ability_pill";
@import "molecules/buttons/small_button";
@import "molecules/buttons/button_light";
@import "molecules/buttons/back_button";
@import "molecules/buttons/submit_button";
@import "molecules/rich_text";
@import "molecules/dynamic_formset";




/* widgets */
@import "organisms/widgets/image_preview_widget";
@import "organisms/widgets/ability_selection_widget";

/* organisms */
@import "organisms/profile/profile";
@import "organisms/profile/_similar_profiles";
@import "organisms/search/search_form";
@import "organisms/search/search_form_select";
@import "organisms/search/search_result";

/* pages */

@import "pages/search_page";
@import "pages/profile_edit";
@import "pages/onboarding";
@import "pages/signup";
@import "pages/error_page";
@import "pages/password_recovery";
@import "pages/user_suggestion";
@import "pages/profile_bookmarks.scss";

@import "global";

/* modal */
@import "modal/genericModal";
@import "modal/confirm_modal";

@import "components/_alert.scss";

/* testing */

html {
  height: 100%;
}

body {
  font-family: $font-family;
  height: 100%;
  width: 100%;
  position: relative;
  color: color('text');
  display: flex;
  flex-direction: column;

}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}


.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.full-width-inner {
  max-width: $max-content-width;
  margin-left: auto;
  margin-right: auto;
}

.content-block {
  @include content-block-margins-fl()
}

main {
  //position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  width: 100%;
  //padding-bottom: 300px;
}
