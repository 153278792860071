.icon_link {
  white-space:nowrap;
  color: color('green');
  text-decoration: none;
  display: inline-block;
}

@media (hover: hover) {

  .icon_link:hover {
    color: color('blue');
  }

}
