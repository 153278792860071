header {
  border-bottom: 2px solid color('gray');
  position: relative;
  overflow: visible;
  z-index: 10;
  background-color: color('white');
}

.header_inner {

  display: flex;
  flex:1;
  align-items: center;
  margin: 0px auto;
  max-width: 1440px;
  height: 85px;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  font-size: 17px;



  position: relative;
  overflow: visible;

  @include breakpoint('vp-main') {
    @include fluid-property('height', $main-stage-min-width,960px, 85px, 145px);

  }

  @include breakpoint('vp-switch') {
    height: 145px;
  }


}

.header__logo {
    z-index: 0;
    flex: 1;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    text-decoration: none;



    height: 100%;
    max-height: 90px;

    width: auto;
    background-image: url('../assets/img/media-innovators-logo.svg');
    background-repeat: no-repeat;
  // background-position: center;
  background-position: 10px 0px;


  span {
    display: none;
  }
}
