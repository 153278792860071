/*------------------------------------*\
    $VARIABLES
\*------------------------------------*/

$debug: false;

// relative min with for fluid calculations
$relative-min-width: 300px;

//Layout
$mobile-min-width: 320px;
$mobile-max-width: 720px;

$main-stage-min-width: 720px;
$main-stage-width: 1440px;

$max-content-width: 675px;

// breakpoints
// inspired from: https://ricostacruz.com/til/css-media-query-breakpoints
$breakpoints: (
        vp-375: (width: $mobile-min-width),
        vp-480: (width: 480px),
        vp-main: (width: $main-stage-min-width),
        vp-switch: (width: 960px),
        vp-max: (width: $main-stage-width),
);


// v-spaces
//

$v-space-xs: 15px;
$v-space-s: 25px;
$v-space-m: 50px;
$v-space-l: 75px;
$v-space-xl: 100px;

$v-space-map: (
        reset: 0px,
        xxs: 10px,
        xs: 30px,
        s: 25px,
        m: 50px,
        ml: 60px,
        l: 75px,
        xl: 100px
);

$v-space-map-mobile: (
        reset: 0px,
        xxs: 10px, // used
        xs: 15px, // used
        s: 20px, // used
        m: 25px, // used
        ml: 50px, // used
        l: 65px, // used
        xl: 90px
);

$h-space-map: (
        reset: 0px,
        xxs: 10px,
        xs: 20px,
        s: 30px,
        sm: 30px,
        m: 75px,
        l: 115px,
        xl: 165px,
        xxl: 240px
);

$h-space-map-mobile: (
        reset: 0px,
        xxs: 8px, // used
        xs: 12px,
        s: 10px, // used
        sm: 30px, // used
        m: 75px,
        l: 115px,
        xl: 165px,
        xxl: 240px
);


$colors: (
  /* Layout */
        'warning': #ff7b008a,
        'warning-text': #ff7b00,
        'orange': #FCD458,
        'white': #fff,
        'gray': #e5e5e5,
        'placeholder': #c6c6c6,
        'off-white':#f6f5f3,
        'footer-gray':#F6F5F3,
        'blue': #5ac1d2,
        'blue-light': rgb(196, 232, 239),
        'blue-bright': #1ec6dd,
        'text-dark': #023f51,
        'text': #646363,
        'pink': #bd0058,
        'green': #007083,
        // 'oceanblue': #023F51,
        'dark-green': #023F51,
);




//Typography
$font-family-headline-serif: "flood-std", sans-serif;
$font-family-headline: "BlenderProBold", sans-serif;
$font-family: "BlenderProMedium", sans-serif;
$font-family-regular: "BlenderProBook", sans-serif;

$base-font-multiplier: 1;
// base font size for page
$font-size-base: 16px;
