.ability_pill__delete_button {
  cursor: pointer;
  display: block;
  padding-top:1px;
  padding-left: 2px;
  //background-color: blue;
}

.ability_pill__delete_icon {

  margin: 0px;
  padding: 0px;
  font-size: 18px;
  pointer-events: none;
  color: color('white');


}

.ability_selection_widget__proposal_controls {

  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid color('blue');

  @include add_fluid_space('m', 'margin', 'bottom', 'top');
}

input[type=text].ability_selection_widget__proposal__input {
  background-color: unset;
  border: 0px;
  @include font-medium-14-18();
  &::placeholder {
    color: color('placeholder');
  }

}

.ability_selection_widget__proposal__submit_button {

  cursor: pointer;
  color: color('text-dark');



  &:hover {
    color: color('green');
  }

}

.ability_selection_widget__proposal__submit_button__icon {

  font-size: 40px;
  &:before {
    margin:0px;

    @include fluid-type($mobile-min-width, $main-stage-width, 35px, 50px);
  }

}

.ability_selection_widget__category_select_wrapper {
  position: relative;
  width: 100%;
  border-bottom: 2px solid color('blue');
  @include add_fluid_space('s', 'margin', 'bottom', 'top');

  height: 50px;
  line-height: 4;

  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;
  &:hover {
    &:after {
      color: color('green');
    }

  }

  &:after {
    font-family: "idb_icons";
    font-style: normal;
    font-weight: normal;
    pointer-events: none;
    position: absolute;
    right: 0px;
    color: color('text-dark');



    @include fluid-type($mobile-min-width, $main-stage-width, 35px, 50px);

    text-align: center;

    content: '\e808';
  }

}

select.ability_selection_widget__category_select {

  //background-color: yellow;
  flex: 1;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0px;
  background-image: none;
  position: relative;
  cursor: pointer;
  color: color('text');
  @include font-medium-14-18();
  @include fluid-property('padding', $mobile-min-width, $main-stage-width, 10px, 15px);
  }

.ability_selection_widget__ability_checkbox_container {

  display: grid;
  grid-template-columns: 1fr 1fr;

  input {
    display: none;
    &:checked + label.ability_checkbox__label {
      &:before {
        content: '\e817';
      }
    }
  }

  label.ability_checkbox__label {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      color: color('text-dark');
      @include font-copy-small();

    @include add_fluid_space('s', 'margin', 'right');

      &:hover {
        color: color('green');
      }

      &:before{
        font-family: "idb_icons";
        width: 24px;
        padding-right: 5px;
        @include fluid-type($mobile-min-width, $main-stage-width, 16px, 24px);
        content: '\e816';
      }
  }


}

.ability_selection_widget__ability_limit_error {
  display:none;
  @include add_fluid_space('s', 'margin',  'top');
  margin-left: 10px;
  color: color('orange');
}

.ability_selection_widget__abilities_headline {

}

.ability_selection_widget__ability_pills {
  @include add_fluid_space('xxs', 'margin', 'bottom', 'top');
}

.ability_selection_widget__ability_proposal_headline {
  @include add_fluid_space('m', 'margin', 'top');
}

.ability_selection_widget__ability_proposal_pills {
  @include add_fluid_space('xxs', 'margin', 'bottom', 'top');
}
