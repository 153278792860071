.profile_data_list_item {

  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.profile_data_list_item__label {

  color: color('text-dark');
  @include font-medium-14-18();
}

.profile_data_list_item__icon {
  color: color('text-dark');
  @include fluid-type($mobile-min-width, $main-stage-width, 27px, 55px);
}
