
.dynamic_formset[data-name="references"] {
  margin-left: 30px;
  margin-right: 5px;

  .dynamic_formset__form {
    margin-bottom: 20px;
  }


  .dynamic_formset_add_button {
    margin: 10px auto;
    display: flex;
    justify-content: center;

    border-top: 1px solid color('green');
    padding-top: 20px;

    button {
      -webkit-appearance: none !important;
      appearance: none;
      border-radius: 0px;
      border: 0px;
      cursor: pointer;
      padding: 15px;
    }
  }
}

.dynamic_formset[data-name="references"].onboarding {
  margin: 0px;
}
